$header-transition-time: 400ms;
$header-transition-ease: $ease-in-out-quad;

header {
    padding:20px 0;
    height:80px;

    @include screen-tablet-landscape-and-bigger{
        height: 140px;
    }

    a{
        &:hover{
            color:$color-light;
        }
    }

    .navigation{
        display:flex;
        justify-content: space-between;
    }

    .home{
        width:40%;
        font-weight:$font-weight-medium;

        @include screen-tablet-landscape-and-bigger{
            width:auto;
        }

        a{
            color:$color-primary;
            &:hover{
                color: $color-secondary;
            }
        }
    }

    nav{

        ul{
            list-style:none;
            li{
                display:inline-block;
                margin-left:10px;
                @include screen-tablet-landscape-and-bigger{
                    margin-left:16px;
                }

                &.selected{
                    a{
                        color: $color-secondary;
                        font-weight: $font-weight-medium;
                    }
                }

                a{
                    color:$color-primary;
                    &:hover{
                        color:$color-secondary;
                    }
                }

                &.nav-icon{
                    svg{
                        width:20px;
                        height:20px;
                    }

                    &:hover{
                        svg g path{
                            fill:$color-secondary;
                        }
                    }

                }

                &:last-of-type{
                    margin-left:0px;
                }

            }
        }
    }
}
