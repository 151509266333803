$link-transition-time: 200ms;
$link-transition-ease: $ease-in-out-quad;

// most basic link style
@mixin link--base {
  cursor: pointer;
  text-overflow: ellipsis;
  transition: $link-transition-time $link-transition-ease;
  transition-property: border, color, background-color;
  text-decoration:none;
}

a {
  @include link--base;
}
