.u-has--object-fit-polyfill {
  background-size: cover;
  background-position: center;

  img {
    opacity: 0;
  }
}

.figure-image__item {
    width:100%;
}
