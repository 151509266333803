footer{
    padding-bottom:20px;
    @include screen-tablet-landscape-and-bigger{
        padding-bottom:40px;
    }

    a{
        &:hover{
            color:$color-light;
        }
    }


}