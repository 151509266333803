// sass-lint:disable no-vendor-prefixes, no-important

html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 320px;
    min-height: 100%;
    color:$color-dark;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    font-weight:400;
}


html {
    font-family: $font-stack-primary;
    font-size: $font-size-root;
    font-weight: $font-weight-regular;
    color: $color-text;
    background-color: $color-white;
    min-height: 100%;

    &.no--scroll {
        overflow-y: hidden !important;
    }
}

body {
    @include fluid-type($font-size-body, $font-size-body*0.75);
    line-height: $font-line-height-body;
    hyphens: auto;
    word-break: normal;
}

strong {
	font-weight:$font-weight-super;
}

p{
    hyphens:none;
    letter-spacing: -0.3px;
}

img[data-sizes="auto"] {
    display: block;
    width: 100%;
  }

a{
    color: $color-secondary;
    &:hover{
        color: $color-primary;
    }
}
