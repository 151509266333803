@mixin button--base {
    position: relative;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    vertical-align: top;
    margin: 0;
    text-align: center;
}

button {
    @include button--base;
}
