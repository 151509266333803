main{
    p{
        margin-bottom:20px;
    }
}


.project__title{
    letter-spacing: -0.3px;
    margin-bottom:6px;
    @include screen-tablet-landscape-and-bigger{
        margin-bottom:10px;
    }

    .project__type{
        color: $color-light;
    }

}

.main-content{
    .content__block{
        margin-bottom:40px;
        @include screen-tablet-landscape-and-bigger{
            margin-bottom:80px;
            &.cb--extra-margin{
                margin-bottom:120px;
            }
        }

        &.content__block--move-up{
            margin-top: -10px;
        }

        a{
            &:hover{
                color:$color-light;
            }
        }

    }

    .cb__label{
        color:$color-light;
        letter-spacing: -0.3px;
    }
    .cb__text{
        p{
            hyphens:none;
            width:95%;
        }
        p:last-of-type{
            margin-bottom: 0;
        }
    }
}

.sm-hidden{
    display:none;
    @include screen-tablet-landscape-and-bigger{
        display:block;
    }
}

.figure-image,
.figure-video{
    margin-bottom:30px;
    position:relative;

    @include screen-tablet-landscape-and-bigger{
        margin-bottom: 50px;
    }

    img{
        width:100%;
        max-width:100%;
    }
    figcaption{
        @include fluid-type($font-size-small, $font-size-small*0.833);
        // font-size: $font-size-small;
        position: absolute;
        top:100%;
        padding-top:7px;
        @include screen-tablet-landscape-and-bigger{
            padding-top:10px;
        }
    }

    &.figure-image--extra-margin{
        @include screen-tablet-landscape-and-bigger{
            margin-bottom: 80px;
        }
        margin-bottom: 54px;
    }

    video{
        width:100%;
    }

}

.figure-video{
    figcaption{
        padding-top:0;
    }
}

