.contact{
    margin-bottom:40px;
    .label{
        font-weight:300;
        font-style: italic;
        color: $color-tertiary;
    }
}

.project{
    display:block;
    position:relative;
    margin-bottom:20px;

    @include screen-tablet-landscape-and-bigger{
        margin-bottom: 60px;
    }

    figure {
        background: $color-grey;
        width:100%;
        margin-bottom:7px;
        @include screen-tablet-landscape-and-bigger{
            margin-bottom: 9px;
        }
        img{
            width:100%;
            max-width:100%;
        }
    }
    .info{
        font-size:$font-size-small;
        display:flex;
        width:100%;

        .project__title{
            width:50%;
        }

        .project__type{
            width:50%;
            color:$color-light;
        }
    }
}