.project-info{
    margin-bottom:20px;
    .label{
        font-weight:300;
        font-style: italic;
        color: $color-tertiary;
    }

    p:last-of-type{
        margin-bottom:0;
    }
}

.pr-image{
    margin-bottom:20px;

    &.extra-margin-top{
        padding-top:100px;
    }

}

.pr-text {
    margin-bottom:20px;
    &.extra-margin-top{
        padding-top:100px;
    }
}