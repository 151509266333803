// sass-lint:disable no-vendor-prefixes, brace-style, no-important, no-trailing-whitespace

//
//    A collection of some helper classes for tweaking the layout of the elements.
//
//
//    .u-margin-{direction}--xs      - .u-margin-{direction}--xlg
//    .u-padding-{direction}--xs     - .u-padding-{direction}--xlg
//
//
$spacing-directions: ( 'top', 'left', 'bottom', 'right' );


// Set vars for each
$spacing-direction-done: '';
$spacing-size-done: '';
$spacing-breakpoint-done: false;

// Loop trough all media queries
@each $spacing-breakpoint-modifier, $spacing-media in $breakpoint-list {

    // Loop trough all spacing sizes
    @each $spacing-size-modifier, $spacing-size in $spacing-sizes {

        // Loop trough all spacing directions
        @each $spacing-direction in $spacing-directions {

            // Check if size is responsive or not
            @if (length($spacing-size) == 1) {

                // Check if we should output another margin or skip
                @if ($spacing-direction-done != $spacing-direction and $spacing-size-done != $spacing-size and $spacing-breakpoint-done == false) {
                    $spacing-direction-done: $spacing-direction;
                    $spacing-size-done: $spacing-size-modifier;

                    .u-margin-#{$spacing-direction}--#{$spacing-size-modifier} {
                        margin-#{$spacing-direction}: $spacing-size !important;
                    }

                    .u-padding-#{$spacing-direction}--#{$spacing-size-modifier} {
                        padding-#{$spacing-direction}: $spacing-size !important;
                    }
                }
            } @else {
                @if (map-get($spacing-size, $spacing-breakpoint-modifier) != null) {
                    @media #{$spacing-media} {
                        $spacing-px: map-get($spacing-size, $spacing-breakpoint-modifier);

                        .u-margin-#{$spacing-direction}--#{$spacing-size-modifier} {
                            margin-#{$spacing-direction}: $spacing-px !important;
                        }

                        .u-padding-#{$spacing-direction}--#{$spacing-size-modifier} {
                            padding-#{$spacing-direction}: $spacing-px !important;
                        }
                    }
                }
            }
        }
    }

    // Set to true to skip next breakpoints
    // to prevent duplicate classes when no responsive margin present.
    $spacing-breakpoint-done: true;
}
