.shop-card{

    .title{
        font-weight: $font-weight-book;
    }
    .price{
        font-weight: $font-weight-medium;
        margin-bottom:5px;
    }

}

.colours{
    ul{
        margin-top:10px;
        list-style:none;
        display:flex;
        li{
            display:flex;
            content: ' ';
            width:12px;
            height:12px;
            margin-right:5px;
            &.colour_black{background: #000;}
            &.colour_ecru{ background: #dbcc9e;}
            &.colour_white{background:#FFF; border:1px solid #000;}
            &.colour_lightblue{background: #818D9F;}
            &.colour_blue{background: #0022F5;}
            &.colour_darkblue{background: #2E364D;}
            &.colour_yellow{background: #FFDA00;}
            &.colour_orange{background: #FF4800;}
            &.colour_lila{background: #CEA2FD;}
            &.colour_grey{background: #d3d4d5;}
        }
    }
}

.form-prints{
    .item:hover,
    .item-selected{
        svg g path{
            fill:$color-secondary;
            cursor:pointer;
        }
    }
}

.form-main{
    .form-item{
        margin-bottom:10px;
        &.form-item-margin{
            margin-bottom:20px;
        }
        p.extra{
            font-size:14px;
            line-height:1;
            color:#606060;
            font-weight:300;
            font-style:italic;
        }
    }

    .select-item{

        .item{
            font-weight: $font-weight-book;
        }

        .item-selected{
            color: $color-secondary;
        }

        .item:hover{
            color: $color-secondary;
            cursor:pointer;
        }
        p{
            margin-top:5px;
        }

        .item-linked{
            a{
                color: $color-black;
                &:hover{
                    color:$color-secondary;
                }
            }
        }

    }

    textarea{
        border:none;
        width:100%;
        border-bottom:1px solid black;
        padding:5px;
        font-size:14px;
        //height:100px;
    }
    .textareaElement{
        border-bottom:1px solid black;
        padding:5px;
        width:100%;
        min-height: 20px;
        max-height: 150px;
        overflow-x: hidden;
        overflow-y: auto;
        font-size:14px;
        margin-bottom:5px;
    }

}

.shop-item-visual{
    margin-bottom:20px;

    .title{
        margin-bottom:40px;
    }

    .price{
        font-weight: $font-weight-medium;
        margin-bottom:5px;
    }

    .product-description{
        font-weight: $font-weight-medium;
        margin-bottom:40px;
        p{
            display:block;
            font-size:14px;
            font-style:italic;
            width:75%;
        }
    }

    .shipping{
        margin-bottom:80px;
        p{
            font-size:14px;
            line-height:1;
            color:#606060;
            font-weight:300;
            font-style:italic;
        }
    }

    .colours{
        li:hover{
            cursor:pointer;
            border:2px solid $color-secondary;
        }

        li.selected{
            border:2px solid $color-secondary;
        }

        li.colour_blue{
            &.selected,
            &:hover{
                border-color:#F9D94A;
            }
        }
    }

    .vis{
        position:relative;
        .product-details{
            position:absolute;
            top:0;
            right:0;
            bottom:0;
            left:0;
            width:100%;
            height:100%;
            .detail{
                position:absolute;
                top:0;
                right:0;
                bottom:0;
                left:0;
                width:100%;
                height:100%;
                display:none;

                &.active{
                    display:block;
                }

            }
        }
    }

    .form-next{
        a{
            display:inline-block;
            background:$color-secondary;
            color:white;
            padding:5px;
            &:hover{
                background:black;
            }
        }

        &.disabled{
            a{
                cursor: default;
                background:#DDD;
                &:hover{
                    cursor:default;
                }
            }
        }
    }
}

.shop-item-info{
    background:$color-secondary;
    color: $color-white;
    padding:20px;
    margin-bottom:15px;

    &.sii-hidden{
        display:none;
    }

    h2{
        margin-bottom:40px;
    }

    fieldset{
        margin-bottom:40px;
    }

    .totalAmount{
        display:flex;
        .label{
            width:40%;
            flex: 40% 0;
            font-weight:500;
        }
        .price > div{
            margin-bottom:8px;
            padding-bottom:5px;
            border-bottom:1px solid white;
        }
    }

    fieldset.terms{
        padding-left:40%;
        margin-bottom:40px;

        p{
            font-size:14px;
            font-style:italic;
            a{
                font-weight:600;
                color:white;
            }
        }

    }

    .form-item{
        display:flex;
        label{
            width:40%;
            flex: 40% 0;
            font-weight:500;
        }
        input,
        .textareaElement{
            width:60%;
            flex: 60% 0;
        }

        input[type=text],
        input[type=email]{
            width:100%;
            background:none;
            background:transparent;
            border:none;
            border-bottom:1px solid $color-white;
        }

        .textareaElement{
            border-bottom:1px solid white;
            padding:5px;
            width:100%;
            min-height: 20px;
            max-height: 250px;
            overflow-x: hidden;
            overflow-y: auto;
            margin-bottom:5px;
        }

        margin-bottom:5px;

    }

    p.small{
        font-size:14px;
        font-style:italic;
        a{
            font-weight:600;
            color:white;
        }
    }

    .extra{
        padding-top:5px;
        p{
            display:block;
            font-size:14px;
            padding-left:40%;
            font-style:italic;
        }
    }

    h2.marginTop{
        margin-top:60px;
    }

    .shippingLine{
        font-weight:300;
        display:flex;
        margin-bottom:5px;
        &:last-child{
            margin-bottom:10px;
        }
        .label{
            width:40%;
            flex: 40% 0;
        }
    }


    input[type=submit]{
        background:white;
        color:$color-secondary;
        padding:5px;
        cursor:pointer;
        &:hover{
            background:black;
            color:white;
        }
    }


}