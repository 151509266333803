.work-overview{
    .project-card{
        margin-bottom:70px;
        .text{
            .title{
                display:block;
                margin-bottom:20px;
                color:$color-primary;
                font-weight: $font-weight-medium;
                &:hover{
                    color: $color-secondary;
                }
            }

            p{
                font-weight: $font-weight-book;
                font-style: italic;
            }

        }

        .colours{
            margin-bottom:10px;
        }

    }
}